@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.HpCategories {
    margin: 24px 0 48px;

    .SliderNext,
    .SliderPrev {
        opacity: 0;

        @include mobileAndTablet {
            @include visually-hidden;
        }
    }

    &:hover {
        .SliderNext,
        .SliderPrev {
            opacity: 1;
        }
    }

    &-TopWrapper {
        @include flex($align: center);

        width: 100%;
        margin-block-end: 24px;
    }

    &-SectionTitle {
        flex: 0 0 100%;
        margin-block-end: 0;
    }

    &-NavigationWrapper {
        display: none;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    &-Item {
        @include flex($dir: column, $align: center, $justify: center);

        border: 1px solid $gray-light;
        border-left: none;

        @include desktop {
            height: 100%;
            /* stylelint-disable-next-line declaration-no-important */
            display: flex !important;
        }
    }

    &-Title {
        display: block;
        text-align: center;
        font-size: $fs-s;

        @include desktop {
            font-size: 20px;
            margin-block-start: 40px;
        }
    }

    &-Image {
        max-width: 100px;
        width: auto;
        height: auto;
        margin: 0 auto;
    }

    .slick-slider {
        @include desktop {
            height: 100%;
        }
    }

    .slick-track {
        @include desktop {
            height: 100%;
        }
    }

    .slick-slide,
    .slick-slide > div {
        @include desktop {
            height: 100%;
        }
    }

    .slick-list {
        border: 1px solid $gray-light;
        border-top: none;
        border-bottom: none;
        margin: 0 -1px;

        @include desktop {
            height: 100%;
        }
    }
}

@include mobile {
    .HpCategories {
        margin: 16px 0;

        h3.HpCategories-SectionTitle {
            font-size: 22px;
        }
    }
}

@include tablet {
    .HpCategories {
        &-Wrapper {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@include mobileAndTablet {
    .HpCategories {
        &-Item {
            outline: 1px solid #DDDDDD;
            margin-inline-start: 1px;
            margin-block-end: 1px;
            border: 0;
        }
    }
}

@include desktopAndAbove {
    .HpCategories {
        &-TopWrapper {
            margin-block-end: 39px;
        }

        &-Wrapper {
            grid-template-columns: repeat(6, 1fr);
            margin-block-end: 4rem;
        }

        &-SectionTitle {
            flex: 0 0 33.33%;
            margin-block-end: 0;
        }

        &-NavigationWrapper {
            display: block;
            flex: 0 0 33.33%;
            text-align: center;

            img {
                max-width: 16px;
            }
        }

        .SliderCounterNav {
            border: 1px solid $gray-light;
            display: inline-flex;
            align-items: center;
            padding: .5rem 1rem;
            margin-block-end: 0;
        }

        &-NavigationPage {
            color: $gray;
            padding: 0 1rem;
        }

        &-Item {
            &:hover {
                background-color: rgba($color-primary-light, 0.15);

                .HpCategories-Title {
                    color: $color-primary;
                }
            }
        }

        &-Image {
            max-width: 140px;
            padding: 12px 12px 0;
            max-height: calc(100% - 67px);
        }

        &-Title {
            margin-block-start: 12px;
        }
    }
}
